@import "../../App.scss";
@import "../../variables.scss";

.bookingExplanation,
.bookingResult {
  margin-top: 50px;
  padding: 20px;
  padding-top: 50px;
  color: #111111;
  background: $blue;
  border-radius: 20px;
  position: relative;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (orientation: landscape) {
    padding: 50px;
  }
}
.bookingResult {
  background-color: white;
  &.pastResult {
    background-color: lightgray;
    > .bookingResultDate {
      background-color: lightgrey;
    }
  }
}

.bookingResultDate {
  position: absolute;
  top: -25px;
  left: 5%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $yellow;
  border: 2px solid #111111;
  font-size: 20px;
  padding: 10px 20px;
}

.explanationNumber {
  position: absolute;
  top: -25px;
  left: 5%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: $yellow;
  border: 2px solid #111111;
  font-size: 20px;
}
.explanationHint {
  font-size: 12px;
  // background-color: $purple;
  color: black;
  border-radius: 10px;
  padding: 6px 12px;
  margin: 0px 0px;
  display: flex;
  align-self: stretch;
}

.showBookingButton {
  display: block;
  // margin-left: auto;
  // margin-right: auto;
  margin-top: 20px;
  background-color: #ffe9f3;
  font-family: "Titillium Web", sans-serif;
  font-weight: bold;
  box-shadow: 0px 0px 20px 1px $pink;
  &.selected {
    border: 5px solid $yellow;
    box-shadow: 0px 0px 5px 1px #00000022 inset;
  }
}
.showBookingButtonAlone {
  background-color: $white;
  border: 0px solid $white;
  color: $green;
}

.showBookingButtonGroup {
  background-color: $green;
  border: 0px solid $white;
  color: white;
}

.dateContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 20px;
  input,
  select {
    font-size: 20px;
  }
}

.shareContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.shareButton {
  cursor: pointer;
  // border-radius: 50%;
  margin: 0px 10px;
}
.highlightText {
  margin-left: 5px;
  background: white;
  padding: 2px 5px;
  border-radius: 5px;
  white-space: nowrap;
}

.emailText {
  margin-top: 10px;
  display: flex;
  // margin-left: auto;
  // margin-right: auto;
  color: #000000;
  background: none;
  border: 1px solid black;
  font-family: "Titillium Web";
  padding: 10px 10px;
  border-radius: 5px;
  white-space: nowrap;
  width: fit-content;
}
